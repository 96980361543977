.footer__mobile__container {
  display: none;
}

.footer__content {
  margin-top: 100px;
  border-top: solid 1px #707070;
  margin-bottom: 100px;
}

.footer__border {
  border-left: 1px solid #272727;
  height: 251px;
  margin-top: 40px;
}

.footer__links {
  display: flex;
  flex-direction: column;
  margin-top: 20px;

  a {
    padding: 20px;
    text-decoration: none;
    color: #272727;
  }
}

.footer__contact {
  display: flex;
  margin-top: 40px;

  p {
    margin-left: 20px;
    font-size: 14px;
  }
}

.footer__social {
  display: flex;
  text-align: center;
  justify-content: space-evenly;
  margin-top: 20px;
}

.footer__deals {
  margin-top: 40px;

  h1 {
    font-size: 20px;
  }
}

.footer__email {
  margin-top: 84px;

  input {
    border: none;
    outline: none;
    border-bottom: solid 1px #272727;
    line-height: 44px;
  }

  button {
    width: 140px;
    height: 45px;
    font-size: 11px;
    text-transform: uppercase;
    letter-spacing: 2.5px;
    font-weight: 500;
    color: #000;
    background-color: #fff;
    border: none;
    border-radius: 6px;
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    outline: none;
    margin-left: 20px;
    margin-bottom: 0px;
  }
}

@media only screen and (max-width: 1494px) {
  iframe {
    width: 100%;
  }
}

@media only screen and (max-width: 767px) {
  iframe {
    width: 100%;
  }

  .footer__email {
    button {
      margin-left: 0px;
      margin-top: 20px;
    }
  }
}

// Mobile Responsiveness
@media only screen and (max-width: 768px) {
  .footer__mobile__container {
    display: block;
  }
  .footer__content {
    display: none;
  }
  .footer__mobile {
    display: flex;
    flex-direction: row;
    margin-top: 70px;
  }

  .footer__links {
    font-size: 14px;
  }

  .footer__contact p {
    font-size: 11px;
  }

  .footer__mobile {
    border-top: 1px solid #bababa;
  }

  .footer__border {
    border-left: 1px solid #bababa;
    height: 171px;
    margin-left: 8px;
  }

  .footer__contact {
    margin-left: 10px;
  }

  .footer__links a {
    padding-bottom: 0px;
    margin-left: -10px;
  }

  .footer__social__container {
    text-align: center;
    margin-top: 30px;

    h1 {
      font-size: 20px;
      border-top: 1px solid #bababa;
      padding-top: 20px;
    }

    .footer__social {
      .footer__social__img {
        width: 60px !important;
        height: 60px !important;
      }
    }
  }

  .footer__email {
    margin-top: 0px;
    margin-left: 0px;
    margin-bottom: 0px;

    input {
      border-bottom: none;
      background-color: #f2f2f2;
      width: 100%;
      border-radius: 6px;
      padding-left: 6px;
    }
    button {
      width: 100%;
      border: 1px solid gray;
    }
  }

  .footer__deals {
    margin-bottom: 20px;
  }
}
